








































































































































import { Component, Vue } from 'vue-property-decorator';
import { getCustomerService } from '@/serve/index';

@Component({
  name: 'InstitutionManage',
})
export default class InstitutionManage extends Vue {
  public form = {
    fromUserName: '',
    content: '',
    toUserName: '',
  };
  public page = {
    current: 1,
    size: 10,
  };
  public chatRecordData = [];
  public total = 0;
  public IsIndex = true;
  public IsArrange = false;
  public created() {
    this.getChatRecord();
  }
  /**获取聊天表数据*/
  public async getChatRecord(): Promise<void> {
    const res = await getCustomerService(this.page);
    this.chatRecordData = res.data.records;
    this.total = res.data.total;
  }
  //点击每页显示的条数触发获取数据
  public handleSizeChange(val: any): void {
    this.page.size = val;
    this.getChatRecord();
  }
  //点击第N页触发获取数据
  public handleCurrentChange(val: any): void {
    this.page.current = val;
    this.getChatRecord();
  }
  // 搜索
  public async search() {
    const value = { ...this.page, ...this.form };
    const res = await getCustomerService(this.$changNull(value));
    this.chatRecordData = res.data.records;
    this.total = res.data.total;
  }
  //给表单页面的返回按钮
  public back(refesh: boolean) {
    this.IsIndex = true;
    if (refesh) {
      this.getChatRecord();
    }
  }
  public arrangeBack() {
    this.IsIndex = true;
    this.IsArrange = false;
  }

  // 重置功能
  public resetForm() {
    this.form = {
      fromUserName: '',
      content: '',
      toUserName: '',
    };
    this.page = {
      current: 1,
      size: 10,
    };
    this.getChatRecord();
  }
  //排班
  public arrange() {
    this.IsIndex = false;
    this.IsArrange = true;
    // this.$nextTick(() => {
    //   // (this.$refs.Arrange as Arrange).getArrange(row);
    // });
  }
}
