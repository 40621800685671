import request from '../utils/request'

//获取信息列表
export function getInfo(): Promise<any> {
  return request({
    method: 'GET',
    url: 'admin/user/info',
  })
}

//获取菜单列表
export function getMenu(): Promise<any> {
  return request({
    method: 'GET',
    url: 'admin/menu',
  })
}

//问卷的添加修改
export function getCustomerService(data: any): Promise<any> {
  return request({
    method: 'GET',
    data,
    url: 'manage/zm-customer-service/zm-dialog-list',
    type: 'params',
  })
}